import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";

import {
  Badge,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import Icons from "../../assets/Icons";
import { connect } from "react-redux";


import {
  EMPLOYEEDASHBOARD,
  LOGTICKET,
  LOGIN,
  ADDCATEGORY,
  ADDSUBCATEGORY,
  ADDITEM,
  ADDCOMPANY,
  ADDPROJECTMANAGER,
  APPROVER,
  ADDUSER,
  DASHBOARD,
  ADDTECHNICIAN,
  ADD_TECHNICIAN_MANAGER,
  TICKET_REPORT,
  TICKETDETAIL,
} from "../../Utils/Routes";
import AXIOS from "../../Utils/AXIOS";
function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}



HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function Header(props, { children }) {
  const logoutSession = (SessionType) => {
    // axios
    //   .post(AXIOS.axiosUrl + AXIOS.logoutSession, {
    //     SESSION_ID: props.SESSION_ID,
    //     SESSION_TYPE: SessionType,
    //     USER_ID: props.AUTH_ID,
    //   })
    //   .then((response) => {
    //     localStorage.removeItem("MDM_MasterToken");
    //     navigate("/");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };


  console.log("asdaslkdmasjd", props.LOGGED_IN_DATA);



  const navigate = useNavigate();
  const [vendorAnchorEL, setVendorAnchorEL] = React.useState(null);
  const openVendorDropdown = Boolean(vendorAnchorEL);
  const handleVendorDropdownOpen = (event) => {
    setVendorAnchorEL(event.currentTarget);
  };
  const handleVendorDropdownClose = (path) => {
    setVendorAnchorEL(null);
  };
  const [customerAnchorEL, setCustomerAnchorEL] = React.useState(null);
  const openCustomerDropdown = Boolean(customerAnchorEL);
  const handleCustomerDropdownOpen = (event) => {
    setCustomerAnchorEL(event.currentTarget);
  };
  const handleCustomerDropdownClose = (path) => {
    setCustomerAnchorEL(null);
  };
  const [AdminAnchorEL, setAdminAnchorEL] = React.useState(null);
  const openAdminDropdown = Boolean(AdminAnchorEL);
  const handleAdminDropdownOpen = (event) => {
    setAdminAnchorEL(event.currentTarget);
  };
  const handleAdminDropdownClose = (path) => {
    setAdminAnchorEL(null);
  };
  const handleDrawerToggle = () => {
    props.toggleDrawer(!props.showDrawer);
  };
  const breakPointlg = useMediaQuery(useTheme().breakpoints.up("lg"));
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

   let tempRoutes = [
     {
       label: "Dashboard",
       path: EMPLOYEEDASHBOARD,
     },
     {
       label: "Dashboard",
       path: DASHBOARD,
     },
     {
       label: "Ticket Detail",
       path: TICKETDETAIL,
     },
     {
       label: "Create Ticket",
       path: LOGTICKET,
     },
     {
       label: "ITSM",
       path: "/itsm",
     },

     {
       label: "Create Category",
       path: ADDCATEGORY,
     },
     {
       label: "Create Sub Category",
       path: ADDSUBCATEGORY,
     },
     {
       label: "Create Item",
       path: ADDITEM,
     },
     {
       label: "Create Company",
       path: ADDCOMPANY,
     },
     {
       label: "Assign Project Manager",
       path: ADDPROJECTMANAGER,
     },
     {
       label: "Assign Consultant",
       path: ADDTECHNICIAN,
     },
     {
       label: "Assign Approver",
       path: APPROVER,
     },

     {
       label: "Create User",
       path: ADDUSER,
     },
     {
       label: "Assign Consultant",
       path: ADD_TECHNICIAN_MANAGER,
     },

     {
       label: "Approval Report",
       path: TICKET_REPORT,
     },
     {
       label: "Logout",
       path: LOGIN,
     },
   ];
  

  return (
    <Box sx={{ width: "100%", marginBottom: 8 }}>
      <AppBar
        position="fixed"
        // sx={{}}
        sx={{
          backgroundColor: "#fff",
          // width: `calc(100% - ${props.drawerWidth}px)`,
          // ml: `${props.drawerWidth}px`,
        }}
      >
        <Toolbar variant="dense">
          {/* <Typography variant="h6" color="inherit" component="div">
            Photos
          </Typography> */}
          {/* {!breakPointlg && ( */}
          {/* <IconButton
            onClick={() => {
              handleDrawerToggle();
            }}
          > */}
          {/* <img
              src={Icons.Hamburger_icon_black}
              style={{
                width: 24,
                height: 16,
              }}
            /> */}
          {props.LOGGED_IN_DATA?.COMPANY_LOGO ? (
            <img
              src={
                AXIOS.defaultPort +
                "Support_Portal_api/COMPANY_LOGO/" +
                props.LOGGED_IN_DATA?.COMPANY_LOGO
              }
              style={{
                width: 55,
                height: 55,
                cursor: "pointer",
              }}
              onClick={() => {
                handleDrawerToggle();
              }}
            />
          ) : (
            <img
              src={Icons.Hamburger_icon_black}
              style={{
                width: 24,
                height: 16,
                cursor: "pointer",
              }}
              onClick={() => {
                handleDrawerToggle();
              }}
            />
          )}
          {/* </IconButton> */}
          {/* )} */}
          <h4 style={{ color: "black", fontStyle: "italic" }}>
            Service Portal
          </h4>
          <h4 style={{ color: "black", fontStyle: "", marginLeft: 20 }}>
            |{" "}
            {
              tempRoutes.find((item) => item?.path == props?.ActiveScreen)
                ?.label
            }
          </h4>

          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="show notification"
              size="large"
              color="inherit"
              sx={{ mr: 2 }}
              className="nav-bar-item"
            >
              <Badge badgeContent={"0"} color="error">
                <NotificationsNoneRoundedIcon
                  sx={{
                    color: "gray",
                  }}
                />
              </Badge>
            </IconButton>

            <IconButton
              aria-label="show notification"
              size="large"
              color="inherit"
              sx={{ mr: 2 }}
              className="nav-bar-item"
              onClick={handleOpenUserMenu}
            >
              {/* <Badge badgeContent={4} color="error"> */}
              {/* <LoginRoundedIcon
                onClick={() => logoutSession("SINGLE")}
                sx={{
                  color: COLORS.white,
                }}
              /> */}

              <img src={Icons.profile_img} />
              {/* </Badge> */}
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMountedF
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>Welcome, {props.LOGGED_IN_DATA.USER_NAME}</MenuItem>
              <MenuItem>({props.LOGGED_IN_DATA.USER_ID})</MenuItem>
              <Divider />
              {/* <MenuItem>{props.LOGGED_IN_DATA.DESIGNATION}</MenuItem>
              <Divider /> */}

              <MenuItem>Ph: {props.LOGGED_IN_DATA.USER_MOBILE}</MenuItem>
              <Divider />

              <div
                onClick={() => {
                  localStorage.removeItem("SUPPORT_DATA");
                  navigate("/");
                }}
              >
                <MenuItem>Logout</MenuItem>
              </div>
            </Menu>
            {/* <Avatar
                alt="Cindy Baker"
                src={ICONS.ProfileIcon}
                className="nav-bar-item"
                style={{
                  cursor: "pointer",
                }}
              /> */}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
const mapStateToProps = (state) => ({
  categoryData: state.categoryData.category,
  subCategoryData: state.categoryData.subCategory,
  // authData: state.loginData.clientLogin,
  LOGGED_IN_DATA: state.loginData.clientLogin,
});

export default connect(mapStateToProps, {})(Header);
