import DummyIcon1 from "./dummy-icon1.png";
import backIcon from "./back.png";
import login_bg from "./loginBg.svg";
import login_cover from "./loginCover.svg";
import login_vendor_img from "./login-vendor-img.png";
import samishti_logo from "./samishti-logo.svg";
import gray_left_arrow from "./left-arrow-gray.svg";
import blue_left_arrow from "./left-arrow-blue.svg";
export default {
  DummyIcon1,
  backIcon,
  login_vendor_img,
  login_bg,
  login_cover,
  samishti_logo,
  gray_left_arrow,
  blue_left_arrow,
};
