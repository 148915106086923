export const LOGIN = "/";
export const DASHBOARD = "/v1/dashboard";
export const LOGTICKET = "/v1/logticket";
export const UNASSIGNED = "/v1/unassigned";
export const EMPLOYEELOGIN = "/v1/employeelogin";
export const TICKETDETAIL = "v1/dashboard/ticketdetail";
export const OPEN = "/v1/open";
export const WORKINPROGRESS = "/v1/workinprogress";
export const AWAITINGUSERINPUT = "/v1/awaitinguserinput";
export const AWAITINGVENDORINPUT = "/v1/awaitingvendorinput";
export const HOLD = "/v1/hold";
export const CLOSE = "/v1/close";
export const PENDING = "/v1/pending";
export const EMPLOYEEDASHBOARD = "/v1/empdashboard";
export const OPENEMP = "/v1/openemp";
export const WORKINPROGRESSEMP = "/v1/progressemp";
export const HOLDEMP = "/v1/holdemp";
export const ADDCATEGORY = "/addcategory";
export const ADDSUBCATEGORY = "/addsubcategory";
export const ADDITEM = "/additem";
export const ADDCOMPANY = "/addcompany";
export const ADDPROJECTMANAGER = "/addprojectmanager";
export const APPROVER = "/approver";
export const ADDTECHNICIAN = "/technician";
export const ADDUSER = "/adduser";
export const ADDEMPLOYEE = "/addemployee";
export const ADD_TECHNICIAN_MANAGER= "/add-technician-manager";
export const TICKET_REPORT= "/ticket-report";
export const TURN_AROUND_TIME= "/turn-around-time";
export const TURN_AROUND_TIME_MAPPING= "/turn-around-time-mapping";
