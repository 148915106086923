import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainScreen from "../../components/AppDrawer/MainScreen";
import { TICKET_REPORT } from "../../Utils/Routes";
import axios from "axios";
import AXIOS from "../../Utils/AXIOS";
import { saveAs } from "file-saver";
import constants from "../../Utils/constants";

function TicketReport() {
  const [Tbody, setTbody] = useState([]);

  const HeaderData = [
    { Label: "Select", Checkbox: true },
    {
      Label: "Ticket ID",
    },
    {
      Label: "Customer",
    },
    {
      Label: "Type of Request",
    },
    {
      Label: "Category",
    },
    {
      Label: "Sub Category",
    },
    {
      Label: "Assigned Date",
    },
    {
      Label: "Status",
    },
    {
      Label: "Priority",
    },

    {
      Label: "View",
    },
  ];
  const getStatusBadgeStyle = (statusName) => {
    switch (statusName) {
      case "Assigned":
      case "Work In Progress":
        return { backgroundColor: "#013220", color: "#fff" }; // blue
      case "On Hold":
      case "Pending":
        return { backgroundColor: "#dc3545", color: "#fff" }; // yellow
      case "Resolved":
      case "Open":
        return { backgroundColor: "#28a745", color: "#fff" }; // green
      case "Unassigned":
        return { backgroundColor: "#E49B0F", color: "#fff" }; // green
      case "Closed":
        return { backgroundColor: "#28a745", color: "#fff" }; // red
      case "Client Approval Awaited":
        return { backgroundColor: "#6c757d", color: "#fff" }; // gray
      case "Effort Approval Awaited":
        return { backgroundColor: "#dc3545", color: "#fff" }; // gray
      default:
        return { backgroundColor: "#6c757d", color: "#fff" }; // default gray
    }
  };

  const CsvHeader = [
    {
      name: "Ticket ID",
      selector: "TICKET_ID",
    },
    {
      name: "Created By",
      selector: "CREATED_BY_NAME",
    },

    {
      name: "Created By Contact",
      selector: "CREATED_BY_CONTACT",
    },
    {
      name: "Created By Email",
      selector: "CREATED_BY_EMAIL",
    },
    {
      name: "Company Name",
      selector: "COMPANY_NAME",
    },
    {
      name: "Type of Request",
      selector: "TYPE_OF_REQUEST",
    },
    {
      name: "Category Name",
      selector: "CATEGORY_NAME",
    },
    {
      name: "Sub Category Name",
      selector: "SUB_CATEGORY_NAME",
    },
    {
      name: "Assigned Date",
      selector: "ASSIGNED_DATE",
    },
    {
      name: "Flag",
      selector: "FLAG",
    },
    {
      name: "Status",
      selector: "STATUS",
    },
    {
      name: "Priority",
      selector: "PRIORITY",
    },
    {
      name: "Pending with",
      selector: "PENDING_WITH",
    },
    {
      name: "Requirement Approved By",
      selector: "APPROVAL1_BY",
    },
    {
      name: "Requirement Approved On",
      selector: "APPROVAL1_ON",
    },
    {
      name: "Requirement Approved At",
      selector: "APPROVAL1_AT",
    },
    {
      name: "Requirement Remarks",
      selector: "APPROVAL1_REMARKS",
    },
    {
      name: "Efforts",
      selector: "EFFORTS",
    },
    {
      name: "Efforts Approved By",
      selector: "APPROVAL2_BY",
    },
    {
      name: "Efforts Approved On",
      selector: "APPROVAL2_ON",
    },
    {
      name: "Efforts Approved At",
      selector: "APPROVAL2_AT",
    },
    {
      name: "Efforts Remarks",
      selector: "APPROVAL2_REMARKS",
    },
  ];
  const handleExcelExport = () => {
    let excelData = [...Tbody];
        excelData.map((val) => {
          val.REQUEST_DESCRIPTION = constants.requestType[val.REQUEST];

          let pending = [];
          val.PRIORITY = constants.priorityText[val.PRIORITY];
          val.PENDING_WITH.map((pendg) => {
            pending.push(pendg.USER_NAME);
          });
          val.PENDING_WITH = pending;
        });

    console.log("Asdhasdbhjsadasd", excelData);

    if (excelData.length > 0) {
      // Exclude the "Action" column from csvColumns
      const csvColumns = CsvHeader.filter(
        (column) => column.name !== "Action"
      ).map((column) => column.name);

      const csvRows = excelData.map((item) =>
        csvColumns.map((columnName) => {
          const column = CsvHeader.find((col) => col.name === columnName);
          if (column) {
            let cellValue = "";
            if (typeof column.name === "function") {
              cellValue = column.selector(item);
            } else {
              if (column.name == "Status") {
                cellValue =
                  item[column.selector] == true ? "Active" : "Inactive";
              }
              // if (column.selector == "PENDING_WITH") {
              //   if (item[column.selector]) {
              //     cellValue = item[column.selector].map((val) => cellValueval.USER_NAME);
              //   }
              // }
      
              if (column.selector == "ASSIGNED_DATE") {
                if (
                  item[column.selector] != "" &&
                  item[column.selector] != undefined
                ) {
                  cellValue = new Date(item[column.selector]).toDateString();
                }else{
                  cellValue="";

                }
              } 
                    else {
                        cellValue = item[column.selector] || "";
                      }
            }
            // Wrap cell value in double quotes to handle commas
            return `"${cellValue}"`;
          }
          return ""; // Return an empty value for excluded columns
        })
      );
      const csvContent =
        csvColumns.join(",") +
        "\n" +
        csvRows.map((row) => row.join(",")).join("\n");

      const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(blob, "Approval-Report.csv"); // Use the saveAs function to download the CSV file
    }
  };

  useEffect(() => {
    getApprovalReportData();
  }, []);
  const getApprovalReportData = () => {
    axios
      .get(AXIOS.defaultPort + AXIOS.approvalReport)
      .then((response) => {
        console.log("ASdasbdhajsd", response.data);
        setTbody(response.data);
      })
      .catch((err) => {
        console.log("ASdasdasd", err);
      });
  };
  return (
    <MainScreen drawerWidth={282} Activekey={TICKET_REPORT}>
      <div>
        <div style={{ display: "flex", justifyContent: "flex-end", margin: 1 }}>
          <button
            style={{
              // position: "absolute",
              top: "0px",
              right: "200px",
              width: "100px",
              height: "35px",
              cursor: "pointer",
              backgroundColor: "#219bcc",
              color: "white",
              border: "none",
              borderRadius: "4px",
              zIndex: 0,
              fontSize: 13,
            }}
            // onClick={handleExport}
            onClick={() => {
              handleExcelExport();
            }}
          >
            Excel Export
          </button>
        </div>
        <Card>
          <table
            className="table table-striped table-bordered table-hover table-sm"
            style={{
              textAlign: "center",
              height: "70%",
              borderRadius: "10%",
              fontSize: "80%",
            }}
          >
            <thead
              className="thead-style"
              style={{
                backgroundColor: "red",
              }}
            >
              <tr
                style={{
                  backgroundColor: "red",
                }}
              >
                {HeaderData.map((col, index) => (
                  <th
                    key={index}
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      backgroundColor: "#219bcc",
                    }}
                  >
                    {col.Checkbox ? (
                      <input
                        type="checkbox"
                        // onChange={handleSelectAll}
                        // checked={Tbody.length === filteredRows.length}
                      />
                    ) : (
                      <span>{col.Label}</span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {[].map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td style={{ textAlign: "center", color: "#31434b" }}>
                    <input
                      type="checkbox"
                      // onChange={() => handleCheckboxChange(row["TICKET_ID"])}
                      checked={Tbody.includes(row["TICKET_ID"])}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>{row.TICKET_ID}</td>
                  <td style={{ textAlign: "center" }}>
                    {row.COMPANY_NAME} ({row.CLIENT_ID})
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {row["Type of Request"]}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {row.Category} ({row.CATEGORY_ID})
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {row.SUB_CATEGORY_NAME} ({row["Sub Category"]})
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {row["Assigned Date"]}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span
                      className="badge"
                      style={getStatusBadgeStyle(row.Status)}
                    >
                      {row.Status}
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>{row.Priority}</td>
                  <td style={{ cursor: "pointer", textAlign: "center" }}>
                    {/* <VisibilityIcon onClick={() => handleIconClick(row)} /> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {[].length <= 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 18,
                marginBottom: 5,
              }}
            >
              No Data Found
            </div>
          )}
        </Card>
      </div>
    </MainScreen>
  );
}

export default TicketReport;
